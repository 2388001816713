import { useCallback, useEffect, useState } from "react";

/* Authentication is about being logged in / not logged in, authorization is about checking if a certain action is allowed (even if you are logged in) */
let logoutTimer;

export const useAuth = () => {
  const [token, setToken] = useState(false);
  const [tokenExpirationDate, setTokenExpirationDate] = useState();
  const [userId, setUserId] = useState(null);

  const login = useCallback((uid, token, expirationDate) => {
    // setIsLoggedIn(true);
    setToken(token);
    setUserId(uid);
    const tokenExpirationDate = expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60);/* current date + 1 hour */

    setTokenExpirationDate(tokenExpirationDate);
    localStorage.setItem(
      'userData',
      JSON.stringify({
        userId: uid,
        token: token,
        expiration: tokenExpirationDate.toISOString()
      })
    );/* global JavaScript browser API where a new entry can be add with setItem()  */

  }, []);

  const logout = useCallback(() => {
    // setIsLoggedIn(false);
    setToken(null);
    setTokenExpirationDate(null);
    setUserId(null);
    localStorage.removeItem('userData');
  }, []);

  /*  timer which ensures that we lock the user out automatically if the token expired */

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const reminingTime = tokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, reminingTime);
    } else {
      /* If we locked out manually, we want end up with multiple timers */
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpirationDate])

  /* auto-login */
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('userData'));

    if (storedData && storedData.token && new Date(storedData.expiration) > new Date()) {
      login(storedData.userId, storedData.token, new Date(storedData.expiration));
    }
  }, [login]) /* useEffect always runs after the render cycle, react will already render this component in the unauthenticated state by default and thereafter run this useEffect function for the first time */
  return { token, login, logout, userId };
}